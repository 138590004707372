.start-the-story-container{
    width: 90%;
    margin: 4.375rem auto;
}
.start-the-story-container h1, 
.start-the-story-container h2,  
.start-the-story-container h3,
.start-the-story-container h4,
.start-the-story-container p {
font-family: 'Gothic A1';
color: #4B4B45;
}
.start-the-story-container h1 {
font-family: 'Gothic A1';
font-size: 2.25rem;
font-weight: 700;
line-height: 2.25rem;
}
.start-the-story-container h2 {
    font-weight: 700;
    font-size: 1.5rem;
    }
.start-the-story-container h3 {
        font-weight: 700;
        font-size: 1.125rem;
}
.start-the-story-container p {
    font-weight: 500;
    font-size: 1rem;
}
.start-the-story-text-container {
    width: 70%;
}
.multimedia-container {
    min-height: 676px;
    margin: 2rem auto;
    margin-bottom: 6rem;
    background-color: #EEEEED;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
}
.the-candidate-image-container {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 23%; 
    min-width: 13rem;
}
.the-story-candidate-header-container h3 {
    line-height: 1.5rem;
    margin-left: -2.25rem;
    margin-top: -.5rem;
}
.the-story-fa-person {
    line-height: 1.5rem;
    font-size: .875rem;
}
.the-candidate-text-container {
    width: 85%;
}
.each-mind-map-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px solid #E4F0F8;
    box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
    width: 90%;
    margin-bottom: 1rem;
    border-radius: 5px;
    cursor: pointer;
}
.each-mind-map-container:hover {
    box-shadow: none;
}
.each-mind-map-container img {
    border-radius: 5px;
}
.mind-map-container {
    border: 2px solid #FFFFFF;
    border-radius: 5px;
    min-height: 37.25rem;
    width:70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.content-thumbnail {
    bottom: 0;
    height: 100%;
    width: 100%;
}
.content-title {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #E4F0F8;
    border-radius: 0px 0px 5px 5px;
}
.content-title p {
    color: #0078BF;
    padding: 0;
    margin: 0;
}
.mind-map-text-container {
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
}
.featured-photo {
    width: 90%;
    margin: 0 auto;
    position: relative;
    margin-top: 1rem;   
    margin-bottom: 1.5rem;
}
.featured-photo img {
    max-width: 100%;
    height: 100%;
    z-index: -10;
}
.photo-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    z-index: 100;
    background: rgba(75, 75, 69, 0.2);
    opacity: 0;
    cursor: pointer;
}
.photo-overlay-text {
    display: flex;
    align-items: center;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-45%, -50%);
    z-index: 100;
    background-color: #4B4B45;
    border-radius: 5px;
}
.photo-overlay-text p {
    color: white;
    font-weight: 700;
    font-size: .875rem;
    margin: auto;
    padding: .5rem;
}
.fa-open-tab {
    margin: 0 .5rem;
}
.featured-photo img:hover {
    z-index: 100;
}
.photo-overlay:hover {
    opacity: 1;
}
.featured-container {
    box-shadow: none;
}
.featured {
    background-color: #0078BF;
    z-index: 100;
}
.featured p {
    color: #FFFFFF;
}