@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');

.tab-container {
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
/* gap: ; */
width: 100%;
box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
margin-bottom: 1rem;
border-radius: 5px;
cursor: pointer;
}
.tab-text-container {
    margin: 1rem;
    position: relative;
    width: 100%;
}
.tab-container h3 {
color: #0078BF;
font-family: 'Gothic A1';
font-style: normal;
font-weight: 700;
font-size: 1.125rem;
line-height: 1.71875rem;
margin-bottom: .5rem;
}
.overview {
    margin-bottom: 0;
    font-size: .875rem;
}
.fa-minus,
.fa-plus {
    color: #0078BF;
    position: absolute;
    left:95%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.125rem;
}
.expanded-tab {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
}
.tab-container h4 {
    font-weight: 700;
    margin-bottom: 1rem;
    color: #4B4B45;
    }
.tab-section {
    width:100%;
    padding: 1.5rem;
    margin: 0;
    border: 1px solid #E4F0F8;
}
.tab-section p,
.tab-section li {
   line-height: 1.5rem;
}
.first-section {
    border-left: none;
}
.third-section {
    background-color: #E4F0F8;
    width: 200%;
    font-weight: 600;
}
.third-section p {
margin-bottom: 1.25rem;
}

.hide {
    display: none;
}