.each-gty-topics-tab-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #EEEEED;
}
.each-gty-topics-tab-heading p {
    margin-bottom: 0;
}
.each-gty-topics-tabs {
    width: 95%;
    margin: 1rem auto;
    background-color: #FFFFFF;
    padding-bottom: 1rem;
}
.each-gty-topics-tabs p {
    /* color: black; */
    padding: 0 1rem;
}
.each-gty-topics-tab-heading.active-subtopic {
    background-color:#0078BF;
}
.each-gty-topics-tab-heading p {
    max-height: 2rem;
}
.each-gty-topics-tab-heading.active-subtopic h3 {
    color: #FFFFFF;
}
.each-gty-topics-tabs h3 {
font-size: 1.5rem;
padding-left: 1rem;
padding-top: 1rem;
}
.each-gty-topics-tabs li {
    list-style:circle;
    margin-left: .5rem;
    padding: -.5rem;
}
.each-gty-topics-tab-section {
    display: flex;
    align-items: center;
    background-color: #E4F0F8;
    height: 50%;
    margin: auto;
    margin-right: 1rem;
}
.each-gty-topics-tab-section p {
    font-weight: 500;
    margin-bottom: .5rem;
    margin-top: -.75rem;
}
.gty-bullet-points-container {
    display: flex;
    justify-content: flex-start;
}
.gty-both-quotes-container {
    max-height: 26rem;
    overflow-y: auto;
    width:100%;
}

.no-content {
    display: none;
}