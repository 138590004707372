.find-the-right-fit-container{
    width: 90%;
    margin: 4.375rem auto;
}
.find-the-right-fit-container h1, 
.find-the-right-fit-container h2, 
.find-the-right-fit-container p {
font-family: 'Gothic A1';
}
.find-the-right-fit-container h1 {
    font-family: 'Gothic A1';
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2.25rem;
    }
.find-the-right-fit-container h2 {
    font-family: 'Gothic A1';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    }
.find-the-right-fit-container h3 {
    font-family: 'Gothic A1';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.25rem;
    }
.find-the-right-fit-text-container {
    width: 70%;
}
.find-the-right-fit-text-container p {
    font-weight: 500;
    font-size: 1rem;
}
.find-the-right-fit-text-container p:last-child {
    margin-top: -.75rem;
}
.matching-units-container {
    width: 100%;
    min-height: 676px;
    margin: 2rem auto;
    margin-bottom: 6rem;
    background-color: #EEEEED;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.matching-units-content-container {
    width: 96%;
    margin: 1rem auto;
}
.matching-units-content-container h2 {
    margin-top: 1.75rem;
    margin-left: 1rem;
    margin-bottom: .25rem;
}
.matching-units-content-container h2 + p {
    margin-left: 1rem;
    margin-bottom: 3rem;
   font-weight: 500;
   font-size: .875rem;
}
.filter-controls-and-tabs-container {
    display: flex;
    width: 95%;
    margin: 1rem auto;
}
.left-filter-controls {
    min-height: 28rem;
    max-height: 44.5rem;
    min-width: 19rem;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
    margin-bottom: 3rem;
}
.left-filter-controls-content {
    width: 88%;
    margin: 0 auto;
}
.find-the-right-fir-fa-person {
    font-size: 1.125rem;
}
.left-filter-controls h3 {
    font-size: 1.125rem;
    margin-left: -2rem;
}
.making-assumptions-container {
    background-color: #EEEEED;
    border-radius: 5px;
    width:100%;
    margin: 1rem auto;
}
.making-assumptions-container p { 
    padding: 0;
    margin: 0;
    margin-left: 2.25rem;
    font-size: .875rem;
    font-weight: 500;
}
.making-assumptions-container a { 
    text-decoration: underline;
    color: #4B4B45;
}
.making-assumptions-container p:first-of-type { 
    padding-top: .75rem;
}
.making-assumptions-container p:last-of-type { 
    padding-bottom: .75rem;
}
.making-assumptions-icon {
    padding-top: .75rem;
    margin-top: .25rem;
    margin-left: 1rem;
}
.they-are-a-header {
    margin-bottom: 0rem;
}
.they-want-to-care-header {
    margin-top: 1.5rem;
    margin-bottom: 0;
}
.they-want-to-work-at-header {
    margin-top: 1.5rem;
    margin-bottom: 0;
}
.unit-tabs {
    margin-top: 1rem;
    width: 100%;
    margin-left: 2rem;
}

.ftrf-clear-filters-box {
    margin-top: 3rem;
    background-color: #EEEEED;
    box-shadow: 0px 2px 1px rgba(75, 75, 69, 0.05);
    border-radius: 5px;
    margin-bottom: 2rem;
    width: 40%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
} 
.ftrf-clear-filters-box p {
    color: #D5D5D2;
}
.clear-filter-enabled p {
    color: #4B4B45;
    cursor: pointer;
}
.clear-filter-enabled .fa-lamp {
    color: #0078BF;
}
.no-example-units-container {
    background-color: #FFFFFF;
    padding: .75rem;
    color: #4B4B45;
    border: 2px solid #E4F0F8;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 0;
    overflow-x: auto;
    min-width: 27rem;
  }
  .find-the-right-fit-tab-container {
    z-index: 1000;
    opacity: 100;
    position: relative;
  }
  .no-example-units-container p {
    margin: 0;;
  }