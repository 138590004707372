@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');

.close-the-call-container{
    width: 90%;
    margin: 4.375rem auto;
}
.close-the-call-container h1, 
.close-the-call-container h2, 
.close-the-call-container p {
font-family: 'Gothic A1';
}
.close-the-call-container h1 {
font-family: 'Gothic A1';
font-size: 2.25rem;
font-weight: 700;
line-height: 2.25rem;
}
.close-the-call-container h2 {
    font-family: 'Gothic A1';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5rem;
    }
.closings-container h2 {
    margin-bottom: 1.5rem;
    }
.close-the-call-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.close-the-call-text-container {
margin-right: 3rem;}
.close-the-call-text-container p {
    font-weight: 500;
    font-size: 1rem;
}
.closings-container{
    width: 100%;
    min-height: 676px;
    margin: 2rem auto;
    margin-bottom: 3rem;
}
.after-closing-container {
  
    padding: 1rem;
    max-width: 16rem;
}
.after-closing-container ul {
    padding-left: 1.25rem;
}
/* .tab-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
    margin-bottom: 1rem;
    border-radius: 5px;
    cursor: pointer;
    } */