.point-container {
    width: 100%;
    margin-bottom: 0;
}
.point-container span {
    font-weight: 500;
    font-size: .875rem;
}
.each-point-container {
    width: 95%;
}

.bullet-point {
    display: inline;
    padding: 0;
    margin: 0;

}
.key-word {
    background-color: #F7C612;
    border-radius: 20px;
    font-weight: 600;
    padding: .25rem .45rem;
    /* margin: .25rem; */
}
.key-word.experience-level, 
.key-word.unit-group {
    background-color: #F7C612;
}
