@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');

.know-the-candidate-container{
    width: 90%;
    margin: 4.375rem auto;
}
.know-the-candidate-container h1, 
.know-the-candidate-container h2,  
.know-the-candidate-container h3,
.know-the-candidate-container h4,
.know-the-candidate-container p {
font-family: 'Gothic A1';
color: #4B4B45;
}
.know-the-candidate-container h1 {
font-family: 'Gothic A1';
font-size: 2.25rem;
font-weight: 700;
line-height: 2.25rem;
}
.know-the-candidate-container h2 {
    font-weight: 700;
    font-size: 1.5rem;
    }
.know-the-candidate-container h3 {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
}
.know-the-candidate-container h4 {
    font-weight: 700;
}
.know-the-candidate-container p {
    font-weight: 500;
    font-size: 1rem;
}
.know-the-candidate-container li {
    font-weight: 400;
    font-size: .875rem;
}
.know-the-candidate-text-container {
    width: 70%;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.the-candidate-container {
    width: 100%;
    min-height: 844px;
    margin: 2rem auto;
    margin-bottom: 4rem;
    border: 2px solid #EEEEED;
    border-radius: 5px;
}
.the-candidate-header-container {
    display: flex;
}
.fa-person {
    font-size: 1.5rem;
    margin-right: .5rem;
  
}
.the-candidate-container h2,
.fa-person {
    margin-top: 2rem;
    line-height: 1.5rem;
}

.the-candidate-container-header-info,
.fa-person
{
    margin-left: 2rem;
}
.context-characters-challenge-container {
    display: flex;
    justify-content: space-evenly;
    margin: 1rem;
}
.each-content-container {
    flex: 1;
    padding: 30px;
    border-radius: 5px;
    position: relative;
    min-height: 26rem;
    margin: 1rem;
}
.each-content-header-container {
    display: flex;
}
.fa-icons {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-right: .5rem;
}
.each-content-container button {
    height: 2.5rem;
    margin-top: 4rem;
    position: absolute;
    top: 14rem;
}
button {
    background-color: #00843D;
    border: none;
    box-shadow: 0px 2px 1px rgba(75, 75, 69, 0.05);
    border-radius: 5px;
    padding: .5rem 1rem;
    cursor: pointer;
}
button:hover {
    background-color: #005c2a;
}
button a {
    color: white;
    font-size: .875rem;
}
button a:hover {
    color: white;
}
.content-description {
    position: absolute;
    padding-right: 3rem;
}
.fa-circle-info {
    position: absolute;
    font-size: .875rem;
    margin-top: 18.25rem;
}
.content-info {
    margin-top: 18rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}
.fa-circle-icon {
    margin-top: .3rem;
    margin-bottom: 2rem;
    margin-left: 0;
}
.fa-link-icon {
    margin-right: .5rem;
}
.storytelling-steps {
    margin-top: 3rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    width: 40%;
}
.storytelling-steps button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-width: 12rem;
    min-height: 2.5rem;
}
.storytelling-steps-info p {
    margin-left: 1.5rem;
}
.beyond-the-phone-screen-text-container {
    width: 60%;
    margin-bottom: 2rem;
}
.probing-for-context-characters-challenge-container {
    display: flex;
    justify-content: space-between;
}
.each-question-container {
    flex: 1;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    margin-left: .5rem;
    margin-right: .5rem;
}
.each-question-container:first-child {
    margin-left: 0;
}
.each-question-container:last-child {
    margin-right: 0;
}
.each-question-container li {
    margin-left: -1rem;
    line-height: 1.5rem;
}
.additional-questions {
    margin-top: 3rem;
    margin-left: 1rem;
}
.additional-questions li {
    line-height: 1.5rem;
}