/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
/************************************************************************************/
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');
.App {
  padding-bottom: 3rem;
}
.loading-logo {
  font-size: 5rem;
  color: #0078BF;
}
@media (prefers-reduced-motion: no-preference) {
  .loading-logo {
    animation: loading-logo-spin infinite 1.75s linear;
  }
}
@keyframes loading-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h1, h2, h3, h4, h5, p, a, li {
  color: #4B4B45;
}
.logo-banner {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width:30%;
}
.header-image {
  position: absolute;
  top:3vw;
  right: 0;
  width: 55%;
  z-index: -10;
}
.bracket-text-graphic {
  position: absolute;
  top: 8vw;
  left: 4vw;
  width:30%;
  max-width: 100%;
  z-index: -10;
}
.routes-container {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
  border-radius: 20px 20px 0px 0px;
  width: 90%;
  margin: 0 auto;
  min-height: 50vh;
  min-width: 950px;
  margin-top: 22%;
  padding-bottom: .5rem;
  margin-bottom: 1rem;
  z-index: 100;
}
.nav {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background-color: #EEEEED;
  border-radius: 20px 20px 0px 0px;
}
.nav h3 {
  text-decoration: none;
  color:  #0078BF;
  font-size: 1.125rem;
}
.nav-link-container p {
  font-family: 'Gothic A1';
}
.description {
  color:  #0078BF;
  font-size: .875rem;
}
.description {
  margin-top:-.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
}
.divider {
  height: 3rem;
  border-right: 1px solid #0078BF;
  border-left: 1px solid #0078BF;
  position: absolute;
  left: 100%;
}
.nav-link-container:last-child .divider {
  border-right: none;
  border-left: none;
}
.active + .divider {
  border-right: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
}
.active .temp-divider {
  height: 3rem;
  position: absolute;
  left: -1px;
  border-left: 1px solid white;
  background-color: white;
}
.nav-link-container:first-child .temp-divider {
  border-left: none;
  border-right: none;
}
.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-basis: 100%;
  position: relative;
  min-height:6rem;

}
.nav-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:6rem;
  flex: 1;
  flex-basis: 100%;
  position: relative;
  margin-right: 1px;
  background-color: #EEEEED;
}
.nav-link-container:last-child {
  margin-right: 0px;
}
.nav-link:hover {
  background-color: #D5D5D2;
}
.nav-link-container:first-child,
.nav-link-container:first-child .active,
.nav-link-container:first-child .nav-link:hover,
.nav-link-container:first-child .active:hover {
  border-radius: 20px 0px 0px 0px;
}
.nav-link-container:last-child,
.nav-link-container:last-child .active,
.nav-link-container:last-child .nav-link:hover,
.nav-link-container:last-child .active:hover {
  border-radius: 0px 20px 0px 0px;
}
.active,
.active:hover {
  background-color:  #0078BF;
}
.nav .active p,
.nav .active h3 {
  color: white;
}
.active .arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.login-screen {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
.login-screen button {
  color: #FFFFFF;
  margin-top: 3rem;
  background-color: #0078BF;
}
.login-screen button:hover {
  background-color: #0067a3;
}