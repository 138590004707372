.important-select-component {
    margin-bottom: .5rem;
    min-width: 14rem;
    position: relative;
    cursor: pointer;
}
.gty .important-select-list-label {
    background-color: #0078BF;
}
.gty .important-select-list-label p {
  color: #FFFFFF;
}
.gty .important-select-list-label {
    position: relative;
    border: 1px solid #EEEEED;
    border-radius: 5px;
    width: 100%; 
    display: flex;
    align-items: center;
}
.gty .important-select-list-label p {
font-size: .875rem;
font-weight: 700;
padding-left: .25rem;
}
.gty .fa-chevron-down {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
}
.gty .fa-chevron-down:hover {
    position: absolute;
    /* right: 20%; */
}
.important-select-list-label p {
    margin: .5rem;
}
.gty .important-select-list-label:hover {
    /* border: 1px solid #0078BF; */
    background-color: #00578A;
}
.important-select-list-container {
    position: absolute;
    border-radius: 5px;
    width: 100%; 
    margin: 0;
    margin-top: .25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
    z-index: 100;
    background-color: #FFFFFF;
}
.hide-dropdown {
    display: none;
}
.important-each-select-list {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.important-each-select-list:hover {
background-color: #EEEEED;
}
.important-each-select-list:first-child:hover,
.important-selected-list-item:first-child {
    border-radius: 5px 5px 0px 0px;
    }
.important-each-select-list:last-child:hover,
.important-selected-list-item:last-child {
    border-radius: 0px 0px 5px 5px;
    }

.important-selected-list-item {
    background-color: #E4F0F8; 
}
.important-selected-list-item:hover {
    background-color: #E4F0F8; 
}
.important-each-select-list p {
    margin: .5rem 0;
    margin-left: 1rem;
}