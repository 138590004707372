.gty-container{
    width: 90%;
    margin: 4.375rem auto;
}
.gty-container h1, 
.gty-container h2, 
.gty-container p {
font-family: 'Gothic A1';
}
.gty-container h1 {
font-family: 'Gothic A1';
font-size: 2.25rem;
font-weight: 700;
line-height: 2.25rem;
}
.gty-container h2 {
    font-size: 1.5rem;
    font-weight: 700;
}
.gty-text-container {
    width: 70%;
}
.gty-text-container p {
    margin-top: -.5rem;
}
.gty-text-container a {
font-weight: 700;
}
.gty-text-container p:first-child {
margin-top: 1rem;
}
.gty-text-container p {
    font-weight: 500;
    font-size: 1rem;
}
.talking-points-container{
    width: 100%;
    min-height: 676px;
    margin: 2rem auto;
    margin-bottom: 6rem;
    background-color: #EEEEED;
    border-radius: 5px;
}
.talking-points-text-container {
    margin-top: 2rem;
    margin-left: 2.35rem;
    padding-top: 2rem;
}
.talking-points-text-container h2 {
    margin-top: .8rem;
    margin-bottom: .25rem;
}
.talking-points-text-container h2 + p {
    font-weight: 500;
}
.gty-main-content-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    background-color: #EEEEED;
}
.gty-left-nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
    margin-top: 2rem;
}
.gty-left-nav-container input {
    border-radius: 5px;
    width: 100%;
   border: 1px solid #4B4B45;
   min-height: 2rem;
   margin-bottom: 1rem;
   padding: .5rem;
}
.gty-left-nav-container input:focus {
    outline-color: #0078BF;
}
.gty-challenge-container, 
.gty-candidate-container {
    background-color: #FFFFFF;
    margin-left: 2rem;
    margin-bottom: 1rem;
    box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
    padding: 1rem;
    width: 100%;
    max-width: 20rem;
    min-width: 16rem;
}

.the-challenge-header,
.the-candidate-header {
    font-weight: 700;
    font-size: 1.125rem;
}
.the-candidate-header {
    margin-top: -1rem;
}
.gty-fa-person {
    font-size: .875rem;
    margin-left: .125rem;
    margin-right: .5rem;

}
.gty-making-assumptions-container {
    background-color: #EEEEED;
    border-radius: 5px;
    width:100%;
    margin: 1rem auto;
}
.gty-making-assumptions-container p { 
    padding: 0;
    margin: 0;
    margin-left: 2.25rem;
    font-size: .875rem;
    font-weight: 500;
}
.gty-making-assumptions-container a { 
    text-decoration: underline;
    color: #4B4B45;
}
.gty-making-assumptions-container p:first-of-type { 
    padding-top: .75rem;
}
.gty-making-assumptions-container p:last-of-type { 
    padding-bottom: .75rem;
}
.gty-making-assumptions-icon {
    padding-top: .75rem;
    margin-top: .25rem;
    margin-left: 1rem;
}
.search-header {
    margin-top: 1.5rem;
}
.clear-filters-box {
    margin-left: -9rem;
    background-color: #EEEEED;
    box-shadow: 0px 2px 1px rgba(75, 75, 69, 0.05);
    border-radius: 5px;
    margin-bottom: 2rem;
    width: 25%;
    min-width: 8rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
} 
.clear-filters-box p {
    color: #D5D5D2;
}
.clear-filter-enabled p {
    color: #4B4B45;
    cursor: pointer;
}
.clear-filter-enabled .fa-lamp {
    color: #0078BF;
}
.gty-topics-tabs {
    width: 100%;
    height: 100%;
    border: 2px solid #FFFFFF;
    margin: 2rem;
    margin-top: 2rem;
    min-height: 6rem;
}
.gty-topics-tabs p {
    color: black;
}
.no-gty-topics-tabs h3 {
    padding: 1rem;
}
.no-each-gty-topics-tabs {
    width: 95%;
    margin: 1rem auto;
    background-color: #FFFFFF;
    padding-bottom: 1rem;
}
.related-talking-points-divider {
    margin-top: 3rem;
    margin-bottom: -.5rem;
}
.gty-topics-tabs i {
    margin-left: 1.5rem;
    font-weight: 500;
    color: #4B4B45
}
.get-to-yes-tabs-container {
position: relative;
z-index: 1000;
opacity: 100;
}
.gty-no-example-units-container {
    background-color: #FFFFFF;
    padding: 1rem;
    color: #4B4B45;
    border: 2px solid #E4F0F8;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 0;
    overflow-x: auto;
    min-width: 27rem;
    max-width: 29rem;
    margin-left: 2rem;
    margin-top: 1rem;

  }
  .gty-no-example-units-container p {
    color: #4B4B45;
    margin: 0;
    font-size: 1rem;
  }