.right-fit-tab-container {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(75, 75, 69, 0.25);
    margin-bottom: 1rem;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #E4F0F8;
    max-height: 24rem;

    }
    .right-fit-tab-text-container {
        margin: 1rem;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        
    }
    .right-fit-tab-text-container .fa-minus,
    .right-fit-tab-text-container .fa-plus {
        color: #0078BF;
        position: absolute;
        left:90%;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.125rem;
    }
    .right-fit-tab-container h3 {
    color: #0078BF;
    font-family: 'Gothic A1';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.125rem;
    margin:0;
    }
    .right-fit-expanded-container {
        min-height: 10rem;
        width: 100%;
        display: flex;
        flex: 1;
    }
    .right-fit-expanded-container.hide-container {
        display: none;
    }
    .ideal-candidate-container {
        width: 45%;
        border-top: 1px solid #E4F0F8;
        padding-bottom: 1rem;
        overflow-y: auto;
    }
    .example-units-container {
        background-color: #E4F0F8;
        width: 55%;
        padding-bottom: 1rem;
        overflow-y: auto;
    }
    .ideal-candidate-container h4,
    .example-units-container h4 {
        margin: 1rem;
     }
     .ideal-candidate-container li {
        padding-right: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
     }
     .example-units-container li {
         padding-right: 1rem;
         font-weight: 500;
         color: #000;
         line-height: 1.5rem;
      }
      .no-units-header {
        margin-left: 1rem;
        font-weight: 500;
      }
